<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <!-- Organization -->
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <organization-selector
          :organization="auction.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId, organization) => {
              auction.organization.id = organizationId;
              auction.allowedLocations = [];
              onFormChanged();
            }
          "
          :disabled="!!auction.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
      >
        <locations-selector
          :locations="auction.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="auction.organization.id"
          @locationsChanged="
            (locations) => {
              auction.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.allowedLocations" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.MANAGERS')} (*)`"
        :placeholder="$t('COMMON.MANAGERS')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
      >
        <users-selector
          :placeholder="$t('COMMON.MANAGERS')"
          :users="auction.managers"
          :filterable="true"
          :showAll="false"
          :filterOrganization="auction.organization.id"
          @usersChanged="
            (managers) => {
              auction.managers = managers;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.managers" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('AUCTIONS.NAME')} (*)`"
        :placeholder="$t('AUCTIONS.NAME')"
        v-model="auction.name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.OBJECT_TYPE')} (*)`"
        :placeholder="$t('AUCTIONS.OBJECT_TYPE')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.OBJECT_TYPE')"
          v-model="auction.object_type"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in OBJECT_TYPES"
            :key="key"
            :label="$t(`AUCTIONS.OBJECT_TYPE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.object_type" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.AUCTION_TYPE')} (*)`"
        :placeholder="$t('AUCTIONS.AUCTION_TYPE')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.AUCTION_TYPE')"
          v-model="auction.auction_type"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in AUCTION_TYPES"
            :key="key"
            :label="$t(`AUCTIONS.AUCTION_TYPE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.auction_type" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.ACTIVATED_TIMER')} (*)`"
        :placeholder="$t('AUCTIONS.ACTIVATED_TIMER')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.ACTIVATED_TIMER')"
          v-model="auction.activated_timer"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            :label="$t(`COMMON.YES`)"
            :value="true"
          >
          </el-option>
          <el-option
            class="select-primary"
            :label="$t(`COMMON.NO`)"
            :value="false"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.activated_timer" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.PRE_OPENING_AT')} (*)`"
        :placeholder="$t('AUCTIONS.PRE_OPENING_AT')"
        v-model="auction.pre_opening_at"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('AUCTIONS.PRE_OPENING_AT')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="auction.pre_opening_at"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.pre_opening_at" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.START_AT')} (*)`"
        :placeholder="$t('COMMON.START_AT')"
        v-model="auction.start_at"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('COMMON.START_AT')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="auction.start_at"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.start_at" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.END_AT')} (*)`"
        :placeholder="$t('COMMON.END_AT')"
        v-model="auction.end_at"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('COMMON.END_AT')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            disabled: true,
            minDate: auction.start_at,
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return !auction.start_at;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="auction.end_at"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.end_at" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.LISTINGS_REGISTRATIONS_OPEN_AT')} (*)`"
        :placeholder="$t('AUCTIONS.LISTINGS_REGISTRATIONS_OPEN_AT')"
        v-model="auction.listings_registrations_open_at"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('AUCTIONS.LISTINGS_REGISTRATIONS_OPEN_AT')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            locale: $flatPickrLocale(),
          }"
          class="form-control datepicker"
          v-model="auction.listings_registrations_open_at"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error
        :errors="apiValidationErrors.listings_registrations_open_at"
      />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.LISTINGS_REGISTRATIONS_CLOSE_AT')} (*)`"
        :placeholder="$t('AUCTIONS.LISTINGS_REGISTRATIONS_CLOSE_AT')"
        v-model="auction.listings_registrations_close_at"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('AUCTIONS.LISTINGS_REGISTRATIONS_CLOSE_AT')"
          :config="{
            allowInput: true,
            enableTime: true,
            type: 'datetime',
            disabled: true,
            minDate: auction.listings_registrations_open_at,
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return (
                  date.getDay() === 0 ||
                  !auction.start_at ||
                  !auction.listings_registrations_open_at
                );
              },
            ],
          }"
          class="form-control datepicker"
          v-model="auction.listings_registrations_close_at"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error
        :errors="apiValidationErrors.listings_registrations_close_at"
      />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.DELAY')}`"
        v-model="auction.delay"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.delay" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('AUCTIONS.EXTENSION_TIME')}`"
        v-model="auction.extension_time"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.extension_time" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('AUCTIONS.AUTHORIZED_PAYMENTS')} (*)`"
        :placeholder="$t('AUCTIONS.AUTHORIZED_PAYMENTS')"
      >
        <el-select
          :multiple="true"
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('AUCTIONS.AUTHORIZED_PAYMENTS')"
          v-model="auction.authorized_payments"
          @change="onFormChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in AUCTION_PAYMENTS"
            :key="key"
            :label="$t(`AUCTIONS.PAYMENT_SOURCE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.authorized_payments" />
    </div>

    <div class="form-wrapper full">
      <addresses-selector
        @addressInputChangeChanged="
          (values) => {
            auction = {
              ...auction,
              ...values,
              long: values.longitude,
              lat: values.latitude,
            };
            onFormChanged();
          }
        "
      />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.ADDRESS')} (*)`"
        v-model="auction.address"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.address" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.CITY')} (*)`"
        v-model="auction.city"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.city" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.ZIPCODE')}`"
        v-model="auction.zipcode"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.zipcode" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.STATE')} (*)`"
        :placeholder="$t('COMMON.STATE')"
      >
        <state-selector
          :country="auction.country"
          :state="auction.state"
          :filterable="true"
          :showAll="false"
          @stateChanged="
            (state) => {
              auction.state = state;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.state" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.COUNTRY')} (*)`"
        :placeholder="$t('COMMON.COUNTRY')"
      >
        <country-selector
          :country="auction.country"
          :filterable="true"
          :showAll="false"
          @countryChanged="
            (country) => {
              auction.country = country;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.country" />
    </div>

    <!--
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LONGITUDE')}`"
        v-model="auction.long"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.long" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LATITUDE')}`"
        v-model="auction.lat"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.lat" />
    </div>
-->

    <div class="form-wrapper full">
      <!-- Excerpt -->
      <base-input
        :label="`${$t('COMMON.EXCERPT')}`"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="auction.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          auction.id ? $t("AUCTIONS.EDIT_AUCTION") : $t("AUCTIONS.ADD_AUCTION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Option, Select } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
  AUCTION_PAYMENTS,
  AUCTION_TYPES,
  OBJECT_TYPES,
} from "@/constants/auctions";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CountrySelector,
    flatPicker,
    StateSelector,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    HtmlEditor,
    LocationsSelector,
    UsersSelector,
    AddressesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["auctionData", "formErrors", "loading"],

  data() {
    let auctionData = { ...this.auctionData };
    auctionData = this.$fillUserOrganizationData(auctionData);
    return {
      auction: auctionData,
      OBJECT_TYPES,
      AUCTION_TYPES,
      AUCTION_PAYMENTS,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.auction.end_at = this.$convertDateToUtc(this.auction.end_at);
      this.auction.start_at = this.$convertDateToUtc(this.auction.start_at);
      this.auction.pre_opening_at = this.$convertDateToUtc(
        this.auction.pre_opening_at
      );
      this.auction.listings_registrations_open_at = this.$convertDateToUtc(
        this.auction.listings_registrations_open_at
      );
      this.auction.listings_registrations_close_at = this.$convertDateToUtc(
        this.auction.listings_registrations_close_at
      );
      let auctionData = cloneDeep(this.auction);
      auctionData = this.$fillUserOrganizationData(auctionData);
      this.$emit("auctionSubmitted", auctionData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    auctionData(auctionData) {
      if (auctionData) {
        this.auction = {
          ...this.auction,
          ...cloneDeep(auctionData),
        };
      }
    },
  },
};
</script>
