<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ `${auction.name}` }}</h3>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ auction.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.OBJECT_TYPE") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ $t(`AUCTIONS.OBJECT_TYPE_${auction.object_type}`) }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.AUCTION_TYPE") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ $t(`AUCTIONS.AUCTION_TYPE_${auction.auction_type}`) }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.ACTIVATED_TIMER") }}</dt>
          <dd class="col-sm-8">
            <span v-if="auction.activated_timer">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.PRE_OPENING_AT") }}</dt>
          <dd class="col-sm-8">
            <span v-if="auction.pre_opening_at">
              {{ $formatDate(auction.pre_opening_at, "LLL") }}
            </span>
            <span v-else>-</span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("AUCTIONS.LISTINGS_REGISTRATIONS_OPEN_AT") }}
          </dt>
          <dd class="col-sm-8">
            <span v-if="auction.listings_registrations_open_at">
              {{ $formatDate(auction.listings_registrations_open_at, "LLL") }}
            </span>
            <span v-else>-</span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("AUCTIONS.LISTINGS_REGISTRATIONS_CLOSE_AT") }}
          </dt>
          <dd class="col-sm-8">
            <span v-if="auction.listings_registrations_close_at">
              {{ $formatDate(auction.listings_registrations_close_at, "LLL") }}
            </span>
            <span v-else>-</span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.START_AT") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ $formatDate(auction.start_at, "LLL") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.END_AT") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ $formatDate(auction.end_at, "LLL") }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.EXTENSION_TIME") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ auction.extension_time }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.DELAY") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{ auction.delay }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("AUCTIONS.AUTHORIZED_PAYMENTS") }}</dt>
          <dd class="col-sm-8">
            <span v-for="(item, key) in auction.authorized_payments" :key="key">
              {{ $t(`AUCTIONS.PAYMENT_SOURCE_${item}`) }}
              {{ auction.authorized_payments.length - 1 > key ? "," : " " }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.address"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.city"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.zipcode"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.state"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.country"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LONGITUDE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.long"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LATITUDE") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.lat"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="auction.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="auction.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.MANAGERS") }}</dt>
          <dd class="col-sm-8">
            <users :users="auction.managers" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="auction.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(auction.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(auction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_AMOUNT, TAX_TYPE_PERCENTAGE } from "@/constants/taxes";
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";

export default {
  name: "auction-view-global",

  props: ["auction"],

  components: {
    Users,
    Contacts,
  },

  data() {
    return { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT };
  },

  computed: {},

  created() {},

  methods: {
    auctionUpdated() {
      this.$emit("auctionUpdated", true);
    },
  },

  mounted() {},

  watch: {
    auction(auction) {},
  },
};
</script>
