import {
  OBJECT_TYPE_PROPERTY,
  AUCTION_TYPE_STANDARD,
} from "@/constants/auctions";

export default {
  type: "auctions",
  name: "",
  excerpt: "",
  auction_type: AUCTION_TYPE_STANDARD,
  object_type: OBJECT_TYPE_PROPERTY,
  start_at: null,
  end_at: null,
  activated_timer: null,
  pre_opening_at: null,
  listings_registrations_open_at: null,
  listings_registrations_close_at: null,
  extension_time: null,
  delay: null,
  authorized_payments: [],
  lat: null,
  long: null,
  country: "Canada",
  state: "Quebec",
  city: "",
  zipcode: "",
  address: "",
  relationshipNames: ["organization", "managers", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  managers: [],
  allowedLocations: [],
};
